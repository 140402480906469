import React, { useMemo } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid'
import moment from 'moment'

const PREFIX = 'JotformContent'

const classes = {
	root: `${PREFIX}-root`,
	roleContainer: `${PREFIX}-roleContainer`,
	tabs: `${PREFIX}-tabs`,
	expand: `${PREFIX}-expand`,
	inlineTab: `${PREFIX}-inlineTab`,
	distinguishedTab: `${PREFIX}-distinguishedTab`,
	tabsBackground: `${PREFIX}-tabsBackground`,
	tabPanel: `${PREFIX}-tabPanel`,
	titleBar: `${PREFIX}-titleBar`,
}

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		padding: theme.spacing(4),
	},

	[`& .${classes.roleContainer}`]: {
		paddingTop: theme.spacing(4),
	},

	[`& .${classes.expand}`]: {
		flexGrow: 1,
		padding: 0,
		// marginBottom: theme.spacing(1),
	},

	[`& .${classes.inlineTab}`]: {},

	[`& .${classes.distinguishedTab}`]: {
		margin: 15,
		borderColor: 'white',
		borderWidth: 1,
		borderStyle: 'solid',
	},

	[`& .${classes.tabsBackground}`]: {
		minWidth: 190,
	},

	[`& .${classes.tabPanel}`]: {
		overflow: 'auto',
	},

	[`& .${classes.titleBar}`]: {
		padding: theme.spacing(1),
		borderRadius: 10,
	},
}))

function CustomToolbar() {
	console.log('moment().date(', moment().toISOString())
	return (
		<GridToolbarContainer>
			<GridToolbarExport
				csvOptions={{ fileName: `Dieta Portal Survey Results - ${moment().toISOString()}` }}
				printOptions={{ disableToolbarButton: true }}
			/>
		</GridToolbarContainer>
	)
}

const ProgramContent = ({ submissions }) => {
	console.log('submissions', submissions)
	const { rows, columns } = useMemo(() => {
		const columns = []

		const rows = submissions.map(({ answers }, index) => {
			const row = {}

			Object.entries(answers).map((item) => {
				if (['control_button', 'control_head'].includes(item[1].type)) return

				row.id = index + 1
				const submission = item[1]

				// Hide deleted columns and the one with types
				if (!submission.name || submission.type === 'control_pagebreak' || submission.type === 'control_text')
					return

				if (index === 0) {
					if (submission.type === 'control_matrix') {
						Object.entries(submission.answer || {}).map((answer, i) => {
							columns.push({
								field: `${submission.name}[${i}]`,
								headerName: `${submission.text} >> ${answer[0]}`,
								sortable: false,
								width: '300',
							})
						})
					} else {
						if (submission.name === 'email') {
							columns.unshift({
								field: submission.name,
								headerName: submission.text,
								sortable: true,
								width: '200',
							})
						} else {
							columns.push({
								field: submission.name,
								headerName: submission.text,
								sortable: true,
								width: '200',
							})
						}
					}
				}

				if (submission.type === 'control_matrix') {
					Object.entries(submission.answer || {}).map((answer, i) => {
						row[`${submission.name}[${i}]`] = answer[1]
					})
				} else {
					if (submission.type === 'control_datetime') {
						row[submission.name] = moment(submission.answer).format('YYYY-DD-MM') || null
					} else {
						row[submission.name] = submission.answer || null
					}
				}
			})

			row.created_at = submissions[index].created_at
			return row
		})

		columns.push({ field: 'created_at', headerName: 'Submission Date', width: '100' })
		columns.unshift({ field: 'id', headerName: 'ID', width: '40' })

		return {
			rows: rows,
			columns: columns,
		}
	}, [submissions])

	return (
		<Root className={classes.root}>
			<div style={{ height: 1000, width: '100%' }}>
				<DataGrid
					rows={rows}
					columns={columns}
					pageSize={25}
					rowsPerPageOptions={[5]}
					components={{ Toolbar: CustomToolbar }}
				/>
			</div>
		</Root>
	)
}

ProgramContent.propTypes = {
	location: PropTypes.object,
	auth: PropTypes.shape({}),
}

const mapStateToProps = ({ auth }) => {
	return {
		auth,
	}
}

export default connect(mapStateToProps, actions)(ProgramContent)
